import React from 'react';
import { useState } from 'react';

export default (props) => {

  const pagediv = props.screenWidth < 700
        ?
          <div class="aboutpagedivmobile">
          </div>
        :
          <div class="aboutpagediv">
          </div>

  const aboutus = (
          <div>
            At Light Awaits You, we strive to create unique designs to share and express faith to illuminate to the world the love and light of Jesus Christ. 
          </div>
        );

  const ourmission = (
          <div>
            Bring the Gospel message to light through unique designs that inspire faith and hope.
          </div>
        );

  const ourvision = (
          <div>
            To be a dominant alternative cultural voice drawing individuals to deeper faith and hope through the light of the Gospel.
          </div>
        );

  const contentdiv = props.screenWidth < 700
        ?
          <div class="aboutcontentdivmobile">
            <h2>About Us</h2>
            <p />
            {aboutus}
            <h2>Our Mission</h2>
            <p />
            {ourmission}
            <h2>Our Vision</h2>
            <p />
            {ourvision}
          </div>
        :
          <div class="aboutcontentdiv">
            <h2>About Us</h2>
            <p />
            {aboutus}
            <h2>Our Mission</h2>
            <p />
            {ourmission}
            <h2>Our Vision</h2>
            <p />
            {ourvision}
          </div>

  return (
    <div>
      {pagediv}
      <p />
      <p />
      {contentdiv}
      <p />
    </div>
  );
};

