import './App.css';
import './lightawaitsyou.css';
import './modalbase.css';

import iconsx from './IconsX.png';
import iconsfacebook from './IconsFacebook.png';
import iconsinstagram from './IconsInstagram.png';

import { useState, useEffect } from 'react';
import { DataStore } from 'aws-amplify';

//  Auth is no longer available in aws-amplify version 6.x.x.
//  new calls replacing Auth.currentAuthenticatedUser or Auth.currentSession()
//  are replaced.
//  https://docs.amplify.aws/react/build-a-backend/auth/manage-user-session/#pageMain
import { getCurrentUser } from 'aws-amplify/auth';

// Hub is now in aws-ampilfy/utils in aws-amplify 6.x.x
//import { Hub } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';

import { Authenticator } from '@aws-amplify/ui-react';

import Navbarmenu from './Navbarmenu';

//  router
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// routed content
import Home from './Home';
import Login from './Login';
import Logout from './Logout';
import About from './About';

function App() {

  //  window size related functionality
  //  from https://bobbyhadz.com/blog/react-get-window-width-height
  const [windowSize, setWindowSize] = useState(getWindowSize());

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  //  do the below 2 lines consider a user is logged out just because
  //  the browser is refreshed, but they may still be logged in to
  //  cognito??
  const [user, updateUser] = useState(null);
  const [loggedin, updateLoggedIn] = useState(false);
  const [authactioncount, updateAuthActionCount] = useState(0);

  // add authactioncount as the 2nd arg to useEffect() to create
  // a dependency.  whenever authactioncount changes, useEffect()
  // will be triggered to run and cause the page to be re-rendered
  // (normally not done when using react-router-dom)
  useEffect(() => {
    console.log('running useEffect');
    checkUser();
    setAuthListener();

    //  set page <head> meta data
    document.title = "Light Awaits You";
    document.querySelector('meta[name="description"]').setAttribute("content", "light awaits you");
    document.querySelector('meta[name="keywords"]').setAttribute("content", "apparal t-shirts shirts christian gifts");

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };

    console.log('authactioncount=', authactioncount);
  }, [authactioncount]);

  async function checkUser() {
    try {
      console.log('checkUser');
      const user = await getCurrentUser();
      console.log('checked user ', user);
      updateUser(user);
      if (user == null)
        updateLoggedIn(false);
    } catch (err) {
      // updateUser(null);
    }
  }

  async function setAuthListener() {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          console.log('user signed in');
          console.log(data.payload);
          updateLoggedIn(true);
          updateUser(data.payload.data);
          updateAuthActionCount(authactioncount+1);
          break;
        case 'signUp':
          console.log('user signed up');
          updateAuthActionCount(authactioncount+1);
          break;
        case 'signOut':
          console.log('data from event:', data);
          updateLoggedIn(false);
          updateUser(null);
          updateAuthActionCount(authactioncount+1);
          break;
        case 'signIn_failure':
          console.log('user sign in failed');
          updateAuthActionCount(authactioncount+1);
          break;
        case 'configured':
          console.log('the Auth module is configured');
          updateAuthActionCount(authactioncount+1);
        default:
          break;
      }
    });
  }

  return (
    <div className="App">
      <BrowserRouter>
        <div>
          <Navbarmenu isLoggedIn={loggedin} userData={user} />
        </div>
        <header>
        </header>
        <div>
          <Routes>
            <Route path="/" element={<Home userInfo={user} 
                                           screenWidth={windowSize.innerWidth} /> } />
            <Route path="/about" element={<About 
                                           screenWidth={windowSize.innerWidth} /> } />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </div>
      </BrowserRouter>
      <footer>
        <div class="row">
          <div class="column">
            <h2>Contact Us</h2>
            <p>
              <a href="mailto: lightawaitsyou@gmail.com">
                Email: lightawaitsyou
              </a>
            </p>
          </div>
          <div class="column">
            <h2>Follow Us</h2>
            <p />
            <a href="https://twitter.com/lightawaitsyou">
               <img src={iconsx}
                    alt="Follow @lightawaitsyou"
                    />
            </a>
            &nbsp;
            <a href="https://www.facebook.com/lightawaitsyou">
              <img src={iconsfacebook} alt="Follow lightawaitsyou" />
            </a>
            &nbsp;
            <a href="https://www.instagram.com/lightawaitsyou">
              <img src={iconsinstagram} alt="Follow lightawaitsyou" />
            </a>
          </div>
        </div>
        <div class="copyrightdiv">
          <p />
          <p />
            &copy;&nbsp;Light Awaits You
          <p />
          <p />
        </div>
      </footer>
    </div>
  );
}

export default App;

/*
            <a href="https://twitter.com/x?ref_src=twsrc%5Etfw" 
               class="twitter-follow-button" 
               data-show-count="false">
               <img src="IconsX.png"
                    alt="Follow @lightawaitsyou"
                    />
            </a>
            <script async src="https://platform.twitter.com/widgets.js" 
                    charset="utf-8">
            </script>
*/
