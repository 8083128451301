import React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';

export default () => {
  return (
    <div>
      <p />
      <h2>User logged out?</h2>
      <p />
    </div>
  );
};
