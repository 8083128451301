import React from 'react';
import { useState } from 'react';
import { Text } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import iconx from './IconsX.png';
import etsybanner from './ShopEtsyWebBanner.png';


export default (props) => {

  const pagediv = props.screenWidth < 700
        ?
          <div class="homepagedivmobile">
          </div>
        :
          <div class="homepagediv">
          </div>

  return (
    <div>
      {pagediv}
      <div>
        <p />
        <p />
        Start the conversation with uniquely crafted products from Light Awaits You. Come and browse our shop for your new favorites.
        <p />
        <a href="http://lightawaitsyou.etsy.com">
          <img src={etsybanner} width="450" height="500" />
        </a>
        <p />
      </div>
    </div>
  );
};

/*
        screen width: {props.screenWidth}
*/
